.single-vimeo-background .vimeo-overlay-placeholder.placeholder-mobile {
    display: none
}

@media screen and (max-width:1024px) {
    .single-vimeo-background {
        --parent-height: 100
    }

    .single-vimeo-background .vimeo-overlay-placeholder.placeholder-mobile,
    .single-vimeo-background .vimeo-overlay-placeholder.placeholder-mobile video {
        height: calc(var(--parent-height)*var(--vh, 1vh))
    }

    .single-vimeo-background .vimeo-overlay-placeholder.placeholder-desktop {
        display: none
    }

    .single-vimeo-background .vimeo-overlay-placeholder.placeholder-mobile {
        display: block
    }

    .home-header .shape-polygon {
        display: none !important
    }

    .home-header>.single-vimeo-background {
        scale: 1 !important
    }
}

.marquee .marquee-scroll {
    width: 140%;
    margin-left: -20%;
    display: flex;
    position: relative
}

.marquee .marquee-content {
    display: flex;
    position: relative
}

.marquee .marquee-item {
    width: 15vw
}